.sidebar__content {
    position:sticky;
    top: 1em;
    text-align: center;

    @media (min-width: $mainlayoutswitchbreakpoint) {
        text-align: left;
    }
}

.menu__trigger {
    font-size: 1.1em;
    background-color: $colour3;
    border: 2px solid $colour3;
    display: inline-block;
    border-radius: 0.5em;
    padding: 0.2em 1em;
    margin: 1em 0 1em;
    text-decoration: none;
    color: white;

    &:hover {
        color: white;
        background-color: $dark1;

    }


    @media screen and (min-width: $mainlayoutswitchbreakpoint) {
        display: none;
    }
}

//The below is thanks to https://codepen.io/ruandre/pen/howFi
$background: $colour2; //hsl(210, 45, 10);

ul.sidebar__social {
  display: block;
  font-size: 0;
  list-style: none;
  margin: 0;

  li {
    display: inline-block;
    margin: 0.5rem;
  }

  a, svg {
    display: block;
  }

  a {
    position: relative;
    height: 35px;
    height: 3.5rem;
    width: 35px;
    width: 3.5rem;
  }

  svg {
    height: 100%;
    width: 100%;
  }
}

%social-icon-hover {
    border-radius: 100%;
    //color: $background;
    //fill: $background;
    color: white;
    fill: white;
    transform: scale(1.25);
    transition: background-color 0.5s, transform 0.5s ease-out;
}

$colourmap: (
    icon-2: $colour4,
    icon-1: darken($colour4, 10%),
    icon-3: darken($purp, 20%),
    icon-4: $colour5,
    icon-5: lighten($colour5, 10%),
    icon-6: $colour6,
    icon-7: darken($colour6, 10%),
    icon-8: $colour3,
    icon-9: lighten($colour3, 10%)

);

@each $icon, $bgcolor in $colourmap {

    .#{$icon} {
        color: $bgcolor;//hsl($i * 12, 70, 50);
        fill: $bgcolor;//hsl($i * 12, 70, 50);

        &:hover {
            background: $bgcolor;
            @extend %social-icon-hover;
        }
    }
}



//Trying the new menu idea -----------------------------
.menu--show {
    display: block!important;
}

.menu {
    display:none;

    @media screen and (min-width: $mainlayoutswitchbreakpoint) {
        display: block;
    }
}

.menu__item {
	line-height: 1.1;
	display: block;
    text-decoration: none;
    margin: 0 0 2em;
}

.menu__item-name,
.menu__item-label {
	position: relative;
	display: inline-block;
}

.menu__item-name {
	font-size: 1.25em;
    font-weight: bold;
	line-height: 1.4;
	padding: 0 0.35em;
	transition: color 0.5s;
	transition-timing-function: cubic-bezier(0.2,1,0.3,1);
}

.menu__item-label {
	margin: 0 0 0 0.5em;
    font-weight: 100;
    font-size: 0.8em;
	letter-spacing: 0.05em;
	transform: translate3d(-0.5em,0,0);
	transition: transform 0.5s, color 0.5s;
	transition-timing-function: cubic-bezier(0.2,1,0.3,1);
    display: block;
    margin: 0.5em 0 0 1em;
}

.menu a {
	color: $light1;
}

.menu__item-name::before {
	content: '';
	position: absolute;
	z-index: -1;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 50%;
	opacity: 0.2;
	transform: scale3d(0,1,1);
	transform-origin: 0% 50%;
	transition: transform 0.5s;
	transition-timing-function: cubic-bezier(0.2,1,0.3,1);
}

.menu__item:nth-child(odd) .menu__item-name::before {
	background: $colour1;
}

.menu__item:nth-child(even) .menu__item-name::before {
	background: $colour1;
}

/* Hover */
.menu__item:nth-child(odd):hover,
.menu__item:nth-child(odd):focus {
	color: $colour4;
}

.menu__item:nth-child(even):hover,
.menu__item:nth-child(even):focus {
	color: $colour4;
}


.menu__item--active .menu__item-name::before,
.menu__item--active .menu__item-name::before,
.menu__item--active .menu__item-label::before,
.menu__item--active .menu__item-label::before,

.menu__item:hover .menu__item-name::before,
.menu__item:focus .menu__item-name::before,
.menu__item:hover .menu__item-label::before,
.menu__item:focus .menu__item-label::before {
	transform: scale3d(1,1,1);
    background: $colour4!important;
    opacity: 0.7;
}

.menu__item--active .menu__item-label,
.menu__item:hover .menu__item-label,
.menu__item:focus .menu__item-label {
	transform: translate3d(0,0,0);
}

.menu__item--active .menu__item-label::before,
.menu__item:hover .menu__item-label::before,
.menu__item:focus .menu__item-label::before {
	transform-origin: 0% 50%;
	transition-timing-function: ease;
}
