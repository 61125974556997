/* Huge thanks to Rachel Andrew's Grid by Example site for this */

.sidebar {
    background-color: $dark1;
}

.wrapper {
  display: grid;
  grid-gap: 1em;
}

.wrapper-nogap {
    grid-gap: 0;
}

.content {
  background-color: $light1;
}

@media screen and (min-width: $mainlayoutswitchbreakpoint) {

  /* no grid support? */
  .sidebar {
    float: left;
    width: 19.1489%;
    min-height: 100vh;
  }

  .content {
    float: right;
    width: 79.7872%;
    background-color: $light1;
  }

  .wrapper {
    margin: 0 auto;
    grid-template-columns: 1fr 3fr;
  }

  .header, .footer {
    grid-column: 1 / -1;
    /* needed for the floated layout */
    clear: both;
  }

}

.wrapper > * {
  padding: 1em 2em 2em 2em;
  /* needed for the floated layout*/
  margin-bottom: 10px;
}

.wrapper-nogap > * {
    padding: 0;
}

.wrapper-nogap > .sidebar {
    padding: 1em 2em 2em 2em;
}

/* We need to set the widths used on floated items back to auto, and remove the bottom margin as when we have grid we have gaps. */
@supports (display: grid) {
  .wrapper > * {
    width: auto;
    margin: 0;
  }
}




/* cards */

.card-wrapper {
  display: grid;
  grid-gap: 20px!important;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: minmax(200px, auto);
  margin-top: 1em;
}

.card-wrapper > * {
  padding: 20px;
  margin: 0;
  text-decoration: none;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-wrapper > *:hover {
  text-decoration: underline;
}

.card-wrapper > *:nth-child(odd) {
  background-color:$purp;
  color: #fff;
  border-radius: 42% 58% 64% 36% / 43% 28% 72% 57%;
}

.card-wrapper > *:nth-child(even) {
  background-color: $yellow;
  color: #fff;
  border-radius: 53% 47% 46% 54% / 60% 35% 65% 40%;
}