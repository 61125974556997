.promo {
    padding: 1em;
    background-color: $light1;
    min-height: 300px;
    text-align: left;
    //color: $white1;
    //background-image: url('/layout/memphisbw.svg');
    background-image: url('/layout/dots1.png');
    background-size: 400px;
    background-repeat: repeat;

    @media (min-width: $mainlayoutswitchbreakpoint) {
        padding-top: 2em;
        min-height: 400px;
        background-size: 500px;
        //background-size: cover;
    }

    h1 {
        font-size: 3em;
        background: $colour3;
        color: white;
        display:inline-block;
        padding: 0.2em;
        border-radius: 0.3em;

        @media (min-width: $mainlayoutswitchbreakpoint) {
            font-size: 3.2em;
        }

        .emoji {
            font-size: 0.7em;
        }
    }

    .subtitle {
        font-family: 'Montserrat', sans-serif;
        font-size: 1.5em;
        background: $colour4;
        border-radius: 0.3em;
        //todo- fff with this bg is inaccessible
        color: #fff; 
        display: inline;
        padding: 0.45rem;
        
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone;

        @media (min-width: $mainlayoutswitchbreakpoint) {
            font-size: 2em;
            //mix-blend-mode: difference; //difference, color dodge
        }
    }

    h1:after {
        content: '';
        display: block;
    }

    .subtitle--sub {
        font-family: 'Montserrat', sans-serif;
        font-size: 1.2em;
        background: $colour6;
        display:inline-block;
        padding: 0.2em;
        color: white;

        a {
            color: white;

            &:hover {
                text-decoration-color: $colour2;
            }
        }

        @media (min-width: $mainlayoutswitchbreakpoint) {
            font-size: 1.2em;
        }

    }
}


.promo.promo--home {
    background-image: url('/img/promo-sj2.jpg');
    background-repeat: no-repeat;
    background-size: cover!important;
}
