@import 'base/normalize';
@import 'base/vars';

//Post-vars set, below can now access
@import 'base/typography';

@import 'vendor/skeleton';

@import 'components/blog';
@import 'components/contentholders';
@import 'components/flexbox';
@import 'components/footer';
@import 'components/grid';
@import 'components/logo';
@import 'components/promo';
@import 'components/responsiveembed';
@import 'components/sidebar';
@import 'components/timeline';

.smallprint {
    font-size: 1.5rem;
    margin-top: 1rem;
}

img {
    max-width: 100%;
    height: auto;
}

.hidden {
    display: none;
}

.breathe-bottom {
    margin-bottom: 1em;
}
