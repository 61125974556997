.textlist--header {
    font-size: 2.5rem;
    margin-bottom: 0;
}

.textlist--subtitle {
    font-size: 1.8rem;
    color: darken($white1, 15%);
}

blockquote {
    display:block;
    background: #fff;
    padding: 15px 20px 15px 45px;
    margin: 0 0 20px;
    position: relative;
    color: #666;
    text-align: left;
    border-left: 15px solid $colour3;

    cite {
        color: #999999;
        font-size: 1.75rem;
        display: block;
        margin-top: 1em;
    }

    cite:before {
        content: "\2014 \2009";
    }
}

blockquote::before{
  content: "\201C"; /*Unicode for Left Double Quote*/
  font-size: 4rem;
  font-weight: bold;
  color: #999;
  position: absolute;
  left: 10px;
  top:5px;
}

blockquote::after{
  /*Reset to make sure*/
  content: "";
}

figcaption {
    color: #999;
    font-style: italic;
    margin-bottom: 2em;
}

.code {
    font-family: monospace;
    background-color: white;
    padding: 1em;
}
