
/*
$colour1:#b1cde3;
$colour2:#347cb7;
$colour3:#c6443d;
$colour4:#f3a483;
$colour5:#945c76;
$colour6:#f6bfad;
$colour7:#589ec9;*/

$colour1:#b1cde3;
$colour2:#087CA7;
$colour3:#A63446;
$colour4:#EC9A29;
$colour5:#0D5C63;
$colour6:#03B5AA;
$colour7:#05B2DC;

$pink: #fab9c1;
$turq: #4BA399;/*originally 59b3a9 but inaccessible*/
$purp: #a7a3c6;
$yellow: #f8b96a;
$lightbg: #f2f2f2;

$colour3: $turq;
$colour4: $pink;


$light1:#e5e9ec;
$white1:#f0f3f5;
$dark1: #1C0B19;

$dark2: #222133;

$mainlayoutswitchbreakpoint: "600px";

$big-h1font: 4.768rem;
$big-h2font: 3.815rem;
$big-h3font: 3.052rem;
$big-h4font: 2.441rem;
$big-h5font: 1.953rem;
$big-h6font: 1.25rem;
