.contentholder--bright1 {
    background-color:$colour3;
    color:$white1;

    a {
        color: $colour5;
        font-weight: bold;
    }
}

.contentholder--bright2 {
    background-color:$colour2;
    color:$white1;

    a {
        color: $colour1;
        font-weight: bold;
    }
}

.contentholder--bright3 {
    background-color:$light1;

    a {
        color: $colour2;
    }
}

.contentholder--bright4 {
    color:$white1;
    background-color: $pink;
}

.contentholder--flag {
    background-color: $colour1;
    padding: 1em;
    margin-bottom: 1em;
}

.contentholder--flag:before {
    content: "\01F6A9";
}



.contentholder--bgborder {
    padding: 1em;
    /*background: $colour1;
    -moz-box-shadow: 7px 7px 0px darken($colour1, 15%);
    -webkit-box-shadow: 7px 7px 0px darken($colour1, 15%);
    box-shadow: 7px 7px 0px darken($colour1, 15%);*/

    border-bottom: 5px dotted white;
    color: #222;

    .textlist--subtitle {
        margin-top:1em;
        color: #222;
    }

    .textlist--date {
        font-weight: bold;
        color: #222;
    }

    a {
        /*color: $colour2;*/
        font-weight: normal;

        button {
            border-color: white;
            color: white;
            background-color: $colour2;
        }
    }
}

.contentholder--bgborder:last-of-type {
    border-bottom: none;
}

.contentholder--bgborder--future {
    background: darken($colour1, 15%);
    -moz-box-shadow: 7px 7px 0px darken($colour1, 25%);
    -webkit-box-shadow: 7px 7px 0px darken($colour1, 25%);
    box-shadow: 7px 7px 0px darken($colour1, 25%);

    border:none;
    /*border-bottom: 5px dotted $colour6;*/
}

/*.contentholder--bgborder--future:first-of-type {
    border-top: 5px dotted $colour6;
}*/
