.blog__contentholder {
    width: 85%;
    max-width: 950px;
    margin: 0 auto;
    background-image: linear-gradient(white, $light1);
    margin-top: -4em;
    padding: 1em;
}

.blog__contentholder--left {
    max-width: 950px;
    margin: 1em;
}

.blog__blogitem {
    /*background-color: $white1;
    border-radius: 1em;
    padding: 1em;*/
    border-bottom: 2px solid white;
    padding-bottom: 1.5em;
    margin-bottom: 1.5em;

    p {
        margin-bottom: 0.7em;
    }

    a {
        font-family: 'Montserrat', sans-serif;
        font-size: $big-h4font;
        text-decoration: none;
        background: $colour6;
        display:inline-block;
        padding: 0.2em;
        color: white;
    }
}

.blogitem__date {
    color: $colour4;
    font-weight: bold;
}

ul.pagination {
    list-style-type: none;

    li a {
        color: darken($colour2, 20%);
        text-transform: uppercase;
        font-size: 2rem;
        font-family: 'Montserrat', sans-serif;
        text-decoration: none;
    }

    li a:hover {
        text-decoration: $colour6 underline;
    }
}

.lede {
    font-size: 1.7em;
}

//Blog single post
.large-article-header {
    padding: 3em 1.5em;
    margin-bottom:2em;
}

.large-article-header-content .article-date {
  color: #fefefe;
}

.large-article-header-content .article-title h1 {
    line-height: 4rem;
    color: #fefefe;
    font-size: 2.5rem;
}

@media screen and (min-width: 750px) {
    .large-article-header {
        padding: 3em;
    }

    .large-article-header-content .article-title h1 {
        font-size: 4rem;
    }
    
}

.large-article-header-content .article-details {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
    -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between;

    a {
        color: #fefefe;
    }
}

.large-article-header-content .article-author {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
    margin: 1rem 0 0 0;
    color: #fefefe;

    img {
        margin-right: 1em;
        border-radius: 50%;
        max-width: 80px;
    }
}

.large-article-header-content .article-comments {
    margin: 1rem 0 0 0;
}

.blog__morelinks {
    background-color: $colour6;
    padding: 1em 1em 0.5em 1em;
    color: white;

    a {
        color: darken($colour2, 10%);
    }
}


.credit {
    color: lighten(#222, 30%);
}
