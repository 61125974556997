footer {
    background-color: #333;
    color: white;
    padding: 0.5em 1em 0.05em;
    margin:0;

    a {
        color: white;
    }

    a:hover {
        color: $colour3;
    }
}
